import React, { FC, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Helmet } from "react-helmet-async";
import extensibilitySdk from "@outreach/extensibility-sdk";

import {
  convertDbAccountToAppAccount,
  convertDbOrganizationSettingsToAppOrganizationSettings,
  getDbUser,
  getMyOrganization,
  getOrganizationIdByOutreachOrgId,
  onAuthStateChangedHook,
} from "./services/firebase";
import { isDevelopment, isStaging } from "./services/config";
import { getRoutes } from "./services/routes";
import {
  extensibilitySdkInitializedAtom,
  outreachOrgIdAtom,
  organizationAtom,
  signedInUserAtom,
} from "./state/atoms";
import Loading from "./components/Display/Loading";
import EnvLabel from "./components/Layout/EnvLabel";
import AppContainer from "./components/Layout/AppContainer";

const App: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [waitingForAuth, setWaitingForAuth] = useState(true);
  const [registeredOrgRedirected, setRegisteredOrgRedirected] = useState(false);
  const [isOrgRegistered, setIsOrgRegistered] = useState(false);
  const [signedInUser, setSignedInUser] = useRecoilState(signedInUserAtom);
  const setOrganization = useSetRecoilState(organizationAtom);
  const setExtensibilitySdkInitialized = useSetRecoilState(
    extensibilitySdkInitializedAtom
  );
  const [outreachOrgId, setOutreachOrgId] = useRecoilState(outreachOrgIdAtom);

  const env = isDevelopment ? "Development" : isStaging ? "Staging" : null;

  const content = useRoutes(getRoutes(!!signedInUser));

  useEffect(() => {
    const initExtensibilitySdk = async () => {
      try {
        const ctx = await extensibilitySdk.init();
        setExtensibilitySdkInitialized(true);
        console.log("Extensibility SDK initialized");

        const outreachOrganizationId = ctx?.organization?.id;
        if (outreachOrganizationId) {
          setOutreachOrgId(outreachOrganizationId);

          const response = await getOrganizationIdByOutreachOrgId({
            outreachOrganizationId,
          });
          if (response?.result?.organizationId) {
            setIsOrgRegistered(true);
          }
        }
      } catch (err) {
        console.error("Error initializing Extensibility SDK", { err });
        setExtensibilitySdkInitialized(false);
      }
    };

    initExtensibilitySdk();
  }, [setExtensibilitySdkInitialized, setOutreachOrgId]);

  useEffect(() => {
    if (outreachOrgId && isOrgRegistered && !registeredOrgRedirected) {
      navigate(`/view/${outreachOrgId}`);
      setRegisteredOrgRedirected(true);
      return;
    }
    onAuthStateChangedHook(async (authUser) => {
      try {
        setWaitingForAuth(true);

        if (!authUser) {
          setSignedInUser(null);
          return;
        }

        const { uid, email: authEmail } = authUser;

        if (!authEmail) {
          console.error("Authenticated user has no email");
          return;
        }

        const { result: organization } = await getMyOrganization();
        const dbUser = await getDbUser(uid);
        const appUser = convertDbAccountToAppAccount(dbUser, organization);

        setSignedInUser(appUser);

        if (organization) {
          setOrganization(
            convertDbOrganizationSettingsToAppOrganizationSettings(organization)
          );
        } else {
          console.warn("No organization data found in database");
        }

        const {
          isTrial,
          isOutreachAuthorized,
          isPayingCustomer,
          isFinishedOnboarding,
          organizationId,
        } = appUser || {};

        if (appUser && organization) {
          if (pathname.startsWith("/oauth")) {
            return;
          }

          if (!isPayingCustomer) {
            if (isTrial && !isFinishedOnboarding) {
              navigate("/app/continue-onboarding");
              return;
            }

            if (!organizationId) {
              navigate("/app/wait-for-onboarding");
              return;
            }

            if (!isOutreachAuthorized) {
              navigate("/app/connect-outreach");
              return;
            }
          }

          navigate("/app/dashboard");
          return;
        }
      } catch (err) {
        console.error("Error logging in", { err });
        setSignedInUser(null);
      } finally {
        setWaitingForAuth(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, outreachOrgId, registeredOrgRedirected, isOrgRegistered]);

  return waitingForAuth ? (
    <div className="d-flex w-100 min-h-100vh align-items-center justify-content-center">
      <Loading />
    </div>
  ) : (
    <>
      <Helmet>
        <title>Dashboard | boring</title>
      </Helmet>
      <AppContainer>
        {env && <EnvLabel env={env} />}
        {content}
        <ToastContainer position="top-left" />
      </AppContainer>
    </>
  );
};

export default App;
