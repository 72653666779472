import React, { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import { getMyOrganization } from "../services/firebase";
import { signedInUserAtom } from "../state/atoms";
import Text, { TextVariant } from "../components/Display/Text";

const phrases = [
  "Please wait... We're setting up your account",
  "Lean back... Because magic takes a little time.",
  "Hang tight... Trying to convince the server to cooperate.",
  "Give it a moment... We're gathering the last pixels!",
  "Just a moment... We're almost there!",
];

const WaitForOnboarding: FC = () => {
  const signedInUser = useRecoilValue(signedInUserAtom);
  const [phraseIndex, setPhraseIndex] = useState(0);
  const [showContactMessage, setShowContactMessage] = useState(false);

  const { email, firstName, lastName } = signedInUser || {};

  // Check if the organization is ready every 8 seconds
  useEffect(() => {
    const interval = setInterval(async () => {
      const { status, result } = await getMyOrganization();

      if (status === 200 && result) {
        clearInterval(interval);
        window.location.href = "/app/dashboard";
      }
    }, 8000);

    return () => clearInterval(interval);
  }, []);

  // Cycle through phrases every 2 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setPhraseIndex((prev) => (prev + 1) % phrases.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  // After 20 seconds, show a message about contacting an admin
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContactMessage(true);
    }, 20000);

    return () => clearTimeout(timer);
  }, []);

  if (!signedInUser) {
    return null;
  }

  return (
    <div className="col-12">
      <div className="d-flex h-100 align-items-center justify-content-center m-auto">
        <div className="w-75 w-lg-400px">
          <Text variant={TextVariant.h4} className="mb-1">
            Hi, {firstName || lastName || email || "stranger"}! 👋
          </Text>
          <Text className="mb-1">{phrases[phraseIndex]}</Text>

          {/* After 20 seconds, show a contact message */}
          {showContactMessage && (
            <Text className="text-danger mt-2">
              It seems like your onboarding is taking a bit long. Please contact your admin to make sure you’re allowed into the organization.
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

export default WaitForOnboarding;
